
import { defineComponent, shallowReactive } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { useToast } from "vue-toastification";
import { codes } from "@/Codes/Codes";
import { DateHelper } from "@/Utilities/DateHelper";
import { TimeColsSlatsCoords } from "@fullcalendar/timegrid";
import { fieldFiltered } from "@syncfusion/ej2-vue-pivotview";

export default defineComponent({
	components: {},
	props: {
		id: {
			type: String,
			required: false,
		}
	},
	data() {
		return {
			healthProfileId: null as number | null,
			toast: useToast(),
			settingsData: null as any,
			settingsDataOld: null as any,
			saved: false as boolean,
			adultMinAgeOptions: [16,17,18,19,20,21,22,23,24,25],
			healthProfiles: [] as any,
			procedureRulesInParent: [] as any,
			profileName: "",
			globalProfileName: "Office DNA"
		};
	},
	created() {
		this.healthProfileId = this.id ? Number(this.id) > 0 ? Number(this.id) : null : null;
	},
	async mounted() {
		await this.getSettings();		
	},
	computed: {
		enableSave() {
			for (const grp of this.settingsData) {
				for (const procedureRule of grp.data) {
					for (const rule of procedureRule.rules) {
						if (this.isInError(procedureRule, rule)) {
							return false;
						}
					}
				}
			}
			//console.log(JSON.stringify(this.settingsDataOld), JSON.stringify(this.settingsData))
			return JSON.stringify(this.settingsDataOld) !== JSON.stringify(this.settingsData);
		},
		selectedHealthProfile() {
			return Number(this.$route.params.id);
		},
		healthProfileName() {		
			return this.selectedHealthProfile == 0 ? this.globalProfileName : this.healthProfiles.find(profile => profile.healthProfileId == this.selectedHealthProfile).profileName;
		},
	},
	methods: {
		canEditProfileName(profileName) {
			let canChange = profileName.length > 0  
							&& !this.healthProfiles.some(profile => profile.profileName.toLowerCase() == profileName.toLowerCase())
							&& profileName.toLowerCase() != this.globalProfileName.toLowerCase();

			return canChange;
		},
		async editProfile() {
			const payload: any = {
				profileName: this.profileName,
				healthProfileId: this.selectedHealthProfile,  
			};

			let healthProfile = await ApiService.postData("/ProcedureRules/HealthProfile", payload, {});	

			await this.getHealthProfiles();	
		},
		async getHealthProfiles() {
			const globalProfile = {
				healthProfileId: null,
				profileName: this.globalProfileName
			};

			let profiles = await ApiService.get("/ProcedureRules/HealthProfile");

			if (profiles.status === 200) {
				let data = profiles.data;
				data.unshift(globalProfile); // Add "Global" profile
				this.healthProfiles = [...data]; 
			}

			setCurrentPageTitle("DNA | " + this.healthProfileName);
		},
		ruleStatus(procedureRule: ProcedureRule) {
			let status = "";

			if (!this.rulesInProfile(procedureRule.rules)) {
				if (!procedureRule.rules.some(x => x.healthProfileId == null)) {
					status = "ready";
				}
				else {
					status = "inherit";
				}
			}
			else {
				status = procedureRule.rules.some(x => !x.isActive) ? "off" : "on";
			}

			return status;
		},
		deleteRuleFromProfile(procedureRule: ProcedureRule) {
			let rules = procedureRule.rules.filter(x => x.healthProfileId == this.selectedHealthProfile && x.isActive);

			for (let rule of rules) {
				rule.isActive = false;
			}

			if (!this.procedureRulesInParent.some(x => x == procedureRule.procedureRuleTypeId)) {
				procedureRule.rules = [];
			}

			this.settingsData = [...this.settingsData];
		},
		addRuleToProfile(procedureRule: ProcedureRule) {
			let rules = procedureRule.rules.filter(x => x.healthProfileId == null && x.isActive);
			
			for (let rule of rules) {
				rule.healthProfileId = this.selectedHealthProfile;
			}

			procedureRule.isActive = true;

			this.settingsData = [...this.settingsData];
		},
		addNonGlobalRuleToProfile(rule) {
			rule.isActive = true;
			rule.healthProfileId = this.selectedHealthProfile;
		},
		defaultRuleToInherit(procedureRule: ProcedureRule) {
			if (procedureRule.rules.some(x => x.healthProfileId !== this.selectedHealthProfile)) {
				procedureRule.rules = procedureRule.rules.filter(x => x.healthProfileId !== this.selectedHealthProfile);
			}
			else {
				for (let rule of procedureRule.rules) {
					rule.healthProfileId = null;
					rule.isActive = true;
				}
			}

			this.settingsData = [...this.settingsData];
		},
		getRules(rules) {
			return rules;
		},
		rulesInProfile(rules) {
			return rules.some(x => x.healthProfileId == this.selectedHealthProfile);
		},
		getDisplayClass(field, grp, isProcedure) {
			const darker = isProcedure ? "_darker" : "";
			if (this.rulesInProfile(field.rules)) {
				return !field.rules.some(x => x.isActive === false) ? grp.color + darker : 'lightgrey';
			}
			else {
				return this.selectedHealthProfile == 0 && field.isActive ? grp.color + darker : this.ruleStatus(field) == "inherit" ? grp.color + darker : 'lightgrey';
			}
  		},
		showProcedure(rule, field) {
			let show = false;
			
			if (this.selectedHealthProfile == 0) {
				show = rule.healthProfileId == null;
			}
			else {
				if (this.rulesInProfile(field.rules)) {
					show = rule.isActive && rule.healthProfileId == this.selectedHealthProfile;
				}
				else {
					show = rule.healthProfileId == null;
				}
			}
			
			return show;
		},
		onChangedAge(rules, index) {
			
			if (rules.length < 2) {
				return;
			}
			
			if (index === 0) {
				rules[1].minAge = Math.max(rules[0].maxAge + 1, this.adultMinAgeOptions[0]);
			} else {
				rules[0].maxAge = Math.min(rules[1].minAge - 1, this.adultMinAgeOptions[this.adultMinAgeOptions.length - 1]);
			}		
			
		},
		onChangeMinutes(rule, e){
			console.log("onChangeMinutes", rule, e);
			if (rule.minutes === 0){
				//e.target.type="text"
				e.target.value = null;
				rule.minutes = null;


				console.log("IT IS 0");
			}
		},
		toggleAdvanced(field, e){
			e.preventDefault();
			console.log("field.rules.length", field.rules.length);
			//field.isActive = false;
			if (field.rules.length > 1){				
				field.rules.splice(1);
				field.rules[0].minAge = null;
				field.rules[0].maxAge = null;
				field.rules[0].label = null;	
				field.rules[0].isActive = true;			
			} else {				
				if (field.rules.length === 1) { 
					let adults = JSON.parse(JSON.stringify(field.rules[0]));
					adults.minAge = 18;
					adults.maxAge = 255;
					adults.procedureRuleId = 0;	
					adults.isActive = true;				

					let kids  = JSON.parse(JSON.stringify(field.rules[0]));
					kids.minAge = 0;
					kids.maxAge = 17;
					kids.procedureRuleId = 0;
					kids.isActive = true;					

					field.rules = new Array<RuleDetails>();
					field.rules.push(kids);
					field.rules.push(adults);							
					DateHelper.sortArrayByProperty(field.rules, "minAge", "asc");		
				} else {

				}
			}
			
			console.log(field);
		},
		activeClick(procedureRule: ProcedureRule, event) {
			if (event.target.checked) {
				//ensure rule detail exists				
				if (procedureRule.rules.length === 0 || this.selectedHealthProfile > 0) {
					let rulesDetails = new RuleDetails();
					rulesDetails.isActive = true;
					//let's add some defaults
					if (procedureRule.defaultMinutes) {
						rulesDetails.minutes = null;
					}
					if (procedureRule.isAge) {
						rulesDetails.minAge = 18;
					}
					if (procedureRule.isInterval) {
						let firstOption = procedureRule.options[0];
						rulesDetails.intervalInMonths = firstOption.units == 'years' ? firstOption.duration * 12 : firstOption.duration
					}

					if (this.selectedHealthProfile > 0) {
						rulesDetails.healthProfileId = this.selectedHealthProfile;
						this.addNonGlobalRuleToProfile(rulesDetails);
						//procedureRule.isActive = true;
					}

					procedureRule.rules.push(rulesDetails);
				}
			}
		},
		isInError(procedureRule, ruleDetails) {
			if (!procedureRule.isActive || !ruleDetails.isActive) {
				return false;
			}
			if (procedureRule.isInterval) {
				if (ruleDetails.intervalInMonths !== null && (typeof ruleDetails.intervalInMonths !== 'number' || ruleDetails.intervalInMonths <= 0)) { //is not a positive number
					return true;
				}
			}
			if (procedureRule.defaultMinutes) {
				// if ((ruleDetails.minutes ?? -1) < 0 || ruleDetails.minutes === '') {
				// 	return true;
				// }
			}
			if (procedureRule.isAge) {
				if ((ruleDetails.minAge ?? -1) < 0) {
					return true;
				}
			}

			return false;



		},
		getLabel(rules, index) {			
			if (rules.length < 2) {
				return null;
			}
			let ruleDetails = rules[index];
			if ((ruleDetails.maxAge === null || ruleDetails.maxAge === 255)){
				return "Adults";
			} else if (ruleDetails.minAge! >= 0 && ruleDetails.maxAge! <= 25){
				return "Kids";
			} else {
				return null;
			}


		},
		async submit(e) {
			try {
				e.preventDefault();
				let clinicProcedureRules = new Array();

				for (const grp of this.settingsData) {
					for (const procedureRule of grp.data) {
						if ((procedureRule.isActive || procedureRule.healthProfileId != null)
							|| procedureRule.rules.some(x => x.healthProfileId == this.selectedHealthProfile)) {
							const backendRule = { procedureRuleTypeId: procedureRule.procedureRuleTypeId, healthProfileId: this.selectedHealthProfile, rules: new Array() }
							for (let rule of procedureRule.rules) {
								backendRule.rules.push({
									procedureRuleId: rule.procedureRuleId === 0 ? null : rule.procedureRuleId,
									intervalInMonths: rule.intervalInMonths,
									includeWithScaling: rule.includeWithScaling,
									minAge: rule.minAge,
									maxAge: rule.maxAge,
									active: rule.isActive,
									defaultInMinutes: rule.minutes,
									healthProfileId: rule.healthProfileId == undefined ? null : rule.healthProfileId
								})
							}
							clinicProcedureRules.push(backendRule);
						}
					}
				}

				let res = await ApiService.postData("/ProcedureRules", clinicProcedureRules, {});
				if (res.status == 200) {
					this.getSettings();
					this.saved = true;
					window.scrollTo(0, 0);
					let self = this;
					setTimeout(() => {
						self.saved = false;
					}, 2500);
					console.log(res.data);
				} else {
					this.toast.error(codes.error)

				}
			} catch (e) {
				this.toast.error(codes.error)
			}
		},
		searchGroupColor(array, color) {
			for (let i = 0; i < array.length; i++) {
				const element = array[i];
				if (element.color == color) {
					return i;
				}
			}
			return -1;
		},
		async getSettings() {
			try {
				this.getHealthProfiles();				
				
				let res = await ApiService.get("/ProcedureRules");
				if (res.status === 200) {
					//let data = res.data;
					this.procedureRulesInParent = res.data
						.filter(p => Array.isArray(p.ruleValues?.rules) &&
								p.ruleValues.rules.some(r => r.healthProfileId == null))
						.map(pr => pr.procedureRuleTypeId);
					
					let data = res.data.map(procedure => {
							if (!procedure.ruleValues?.rules) {
								return procedure; // Return as is if no rules exist
							}

							// Gets rules for the global profile
							let matchingRules = procedure.ruleValues.rules.filter(rule => rule.healthProfileId === null);

							// If not in global profile, get the rules for global profile and the loaded profile
							if (this.selectedHealthProfile > 0 && procedure.ruleValues.rules.some(x => x.healthProfileId == this.selectedHealthProfile)) {
								matchingRules = "";
								matchingRules = procedure.ruleValues.rules.filter(rule => rule.healthProfileId == this.selectedHealthProfile);
							}

							// If no rules match specificValue, keep only rules with healthProfileId === null
							let finalRules = matchingRules.length > 0 
								? matchingRules 
								: procedure.ruleValues.rules.filter(rule => rule.healthProfileId === null);

							return {
								...procedure,
								ruleValues: {
									...procedure.ruleValues,
									rules: finalRules
								}
							};
						});

					// Now filter out any rules that have a patient id
					data = data.map(item => {
							if (item.ruleValues && Array.isArray(item.ruleValues.rules)) {
								item.ruleValues.rules = item.ruleValues.rules.filter(rule => !rule.patientId);
							}
							return item;
						});

					let final = [] as any;
					data.forEach((element) => {
						let procedureRule = new ProcedureRule();
						procedureRule.name = element.name;
						procedureRule.procedureRuleTypeId = element.procedureRuleTypeId;
						procedureRule.color = element.procedureGroupColor;
						if (element.options) {
							procedureRule.options = JSON.parse(element.options).internvals.map(o => new Option(o.duration, o.units));
						}
						procedureRule.isAge = element.isAge;
						procedureRule.isInterval = element.isInterval;
						procedureRule.defaultMinutes = element.defaultMinutes;						
						procedureRule.isActive = element.ruleValues && (element.ruleValues.rules.filter(r => r.active).length > 0);
						procedureRule.rules = new Array<RuleDetails>();

						procedureRule.hasAdvanced = element.procedureRuleTypeId === 4 ||  //bitewings 
													element.procedureRuleTypeId === 14 ; //pans only for now

						let rules = new Array();
						if (element.ruleValues && element.ruleValues.rules.length > 0) {
							
							for (const rule of element.ruleValues.rules) {
								let ruleDetails = new RuleDetails();
								ruleDetails.isActive = rule.active;
								ruleDetails.minAge = rule.minAge;
								ruleDetails.maxAge = rule.maxAge;
								ruleDetails.minutes = rule.defaultInMinutes;
								ruleDetails.intervalInMonths = rule.intervalInMonths;
								ruleDetails.procedureRuleId = rule.procedureRuleId;
								ruleDetails.includeWithScaling = rule.includeWithScaling;
								ruleDetails.healthProfileId = rule.healthProfileId;
								procedureRule.rules.push(ruleDetails);
							}
							DateHelper.sortArrayByProperty(procedureRule.rules as [], "minAge", "asc")
						}

						let index = this.searchGroupColor(final, element.procedureGroupColor);
						if (index != -1) {
							final[index].data.push(procedureRule);
						} else {
							let newGrp = { color: element.procedureGroupColor, data: [] as any };
							newGrp.data.push(procedureRule);
							final.push(newGrp);
						}
					});



					//sort final by color with the following order: "yellow", "lightyellow", "lavender", "violet", "orange", "grey", "red"
					final.sort((a, b) => {
						const order = ["yellow", "lightyellow", "lavender", "violet", "orange", "grey", "red"];
						return order.indexOf(a.color) - order.indexOf(b.color);
					});

					final.forEach((group) => {
						// let bitewings = group.data.filter(r => r.procedureRuleTypeId === 4)

						// if (bitewings.length === 1) { //the one needs to be come 2 //only required for upgrade situation
						// 	let bitewingsAdults = JSON.parse(JSON.stringify(bitewings[0]));
						// 	bitewingsAdults.minAge = 18;
						// 	bitewingsAdults.maxAge = 255;
						// 	bitewingsAdults.procedureRuleId = 0;

						// 	let bitewingsKids = JSON.parse(JSON.stringify(bitewings[0]));
						// 	bitewingsKids.minAge = 0;
						// 	bitewingsKids.maxAge = 17;
						// 	bitewingsKids.procedureRuleId = 0;

						// 	const index = group.data.indexOf(bitewings[0]);
						// 	if (index > -1) { // only splice array when item is found
						// 		group.data.splice(index, 1); // 2nd parameter means remove one item only
						// 	}
						// 	group.data.push(bitewingsAdults);
						// 	group.data.push(bitewingsKids);
						// } else {

						// }
						// group.data.forEach((rule) => { 
						// 	if (rule.minAge === 0 && rule.maxAge === 17) {
						// 		rule.name = rule.name + " (kids)";
						// 	} else if (rule.minAge === 18 && rule.maxAge > 200) {
						// 		rule.name = rule.name + " (adults)";
						// 	}
						// })

						DateHelper.sortArrayByProperty(group.data, "name", "asc");
					})

					this.settingsData = final;
					this.settingsDataOld = JSON.parse(JSON.stringify(final));

					console.log(final);
				} else {
					this.toast.error(codes.error)
				}
			} catch (e) {
				console.log(e);
				this.toast.error(codes.error)

			}
		},
	},
});

class ProcedureRule {
	procedureRuleTypeId!: number;
	defaultMinutes!: number | null;
	isInterval!: boolean;
	name!: string;
	isAge!: boolean;
	color!: string;
	options!: Array<Option>; //not sure what to make this yet;
	rules!: Array<RuleDetails>;
	isActive: boolean = false;
	hasAdvanced: boolean = true;	
}

class RuleDetails {
	procedureRuleId!: number | null;
	isActive: boolean = false;
	minutes!: number | null;
	minAge!: number | null;
	maxAge!: number | null;
	intervalInMonths!: number | null;
	label!: string | null;
	includeWithScaling!: boolean | null;
	healthProfileId: number | null = null;
}

class Option {
	duration!: number;
	units!: "years" | "months";
	isSelected!: boolean;
	getLabel() {
		return `${this.duration} ${this.units}`
	}
	/**
	 *
	 */
	constructor(duration, units) {
		this.duration = duration;
		this.units = units;
	}
}
