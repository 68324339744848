<template>	
	<modal :divisor="2.2" cancelText="Cancel" 
		:show="showAppointmentComments" 
		:footer="true" 
		:showCancel="true"
		:title="`Appointment Note`" 
		okText="Save" 
		@ok="saveAppointmentComments"
		@close="showAppointmentComments=false;appointmentComments=null;"
		@no="showAppointmentComments=false;appointmentComments=null;"
		>
		<textarea class="form-control" style="width:100%;height:250px" @change="onNoteChange" @input="onTextAreaInput" v-model="appointment.comments" />
		
		<div class="d-flex py-10 flex-wrap" v-if="appointment.commentState">
			<div class="d-flex align-items-center me-5">
				<input class="mx-1" type="radio" id="new" value="1" v-model="appointment.commentState" />
				<label for="new">New</label>
			</div>
			<div class="d-flex align-items-center mx-5">
				<input class="mx-1" type="radio" id="acknowledged" value="2" v-model="appointment.commentState" />
				<label for="acknowledged">Acknowledged</label>
			</div>
		</div>
	</modal>

	<div class="pl-0 col-12 col-md-6 mb-5 d-flex align-items-stretch">
		<modal cancelText="Cancel" :show="showInsuranceCov" :footer="true" title="Edit Insurance Preferences" okText="Save" @close="closeInsuranceCovModal" @ok="saveInsurancePrefs" @no="closeInsuranceCovModal">
			<div class="row">
				<div class="col-6" v-for="(hist, index) in appointment.proceduresHistory" :key="index">
					<label :for="index" class="form-label">
						{{ hist.description }}
					</label>
					<input type="number" :placeholder="`${hist.description} units covered`" :id="index" class="form-control form-control-solid" />
				</div>
			</div>
			<hr class="my-5" />
			<div class="row">
				<div class="col-6">
					<label for="covgstrt" class="form-label"> Coverage start date </label>
					<input type="date" id="covgstrt" class="form-control form-control-solid" />
				</div>
				<div class="col-6">
					<label for="covgamt" class="form-label"> Coverage amount </label>
					<input type="number" placeholder="Toal coverage amount" id="covgamt" class="form-control form-control-solid" />
				</div>
			</div>
		</modal>
		<div class="card shadow-sm w-100">
			<div class="card-header rotate" :title="`(Appointment ID: ${appointment.appointmentId})`">
				<h3 class="card-title"><i class="fas fa-info-circle fs-2 mx-2"></i> Appointment Information</h3>
				<div class="card-toolbar">
					<button  title="Show appointment note" :class="`btn btm-sm btn-light mx-1 ${appointment.comments ? 'btn-warning' : ''}`" @click="showAppointmentComments = true">
						<i class="fa-regular fa-note-sticky" ></i>
					</button>
					<button title="Click to share appointment" class="btn btm-sm btn-light " @click="copyAppointmentLink(clinicInfo.clinicGuid)">
						<i class="bi bi-clipboard cursor-pointer" ></i>
					</button>
				</div>

			</div>
			<div id="additional_information" class="">
				<div class="card-body">
					<div class="row">
						<div class="col-12">
							<div class="row mb-1">
								<div class="col-6">
									<div class="row mb-5">
										<div class="col" v-if="appointment.patientInitials" :title="`Patient ID: ${appointment.patientId}`">
											<label for="patient_initials">Patient: </label>
											<h4 id="patient_initials">
												{{ appointment.patientInitials }}
											</h4>
										</div>
										<div class="col" v-if="appointment.birthDate" style="border-left: 1px solid #eff2f5">
											<label for="patient_age">Age: </label>
											<h4 id="patient_age">
												{{ yearsDifference(new Date(appointment.birthDate)) }}
											</h4>
										</div>
									</div>

									<div class="row mb-5">
										<div class="col" :title="`Appointment Provider: ${appointment.providerId}-${appointment.providerFirstName} ${appointment.providerLastName}\nPrimary provider: ${appointment.primaryProviderName}`">											
											<label for="patient_primary_provider" class="text-nowrap">Appt. Provider: <i class="fa-solid fa-circle-info"></i>  </label>
											<h4 id="patient_primary_provider">
												{{ `${appointment.providerFirstName} ${appointment.providerLastName}` || "Not specified" }}
											</h4>
										</div>
										<div class="col">
											<label for="operator_id">Operatory: </label>
											<h4 id="operator_id">
												{{ appointment.operatoryId || "" }}
											</h4>
										</div>
									</div>
									<div class="row mb-5">
										<div class="col">
											<label for="DNA">Patient DNA Profile: </label>
											<h4 id="DNA">
												<span v-if="!dnaEditMode">{{ appointment.healthProfileName || "Office DNA" }} </span>
												<i v-if="!dnaEditMode" class="fas fa-pencil-alt cursor-pointer mx-2" @click="editDNAProfile"></i>
												<div v-if="dnaEditMode" class="d-flex align-items-center">
													<select class="form-control w-auto" v-model="appointment.healthProfileId">          											
														<option v-for="profile in healthProfiles" :key="profile.profileName" :value="profile.healthProfileId">{{ profile.profileName }}</option>
													</select>
													<i class="fas fa-save cursor-pointer mx-2" @click="saveDNAProfile"></i>
												</div>
											</h4>
										</div>
										<div v-if="hasPatientDNAProp" class="col">
											<label class="me-3">Edit Patient DNA</label>
											<i v-if="!dnaEditMode" class="fas fa-pencil-alt cursor-pointer mx-2" @click="editPatientDNA"></i>
										</div>
									</div>
								</div>
								<div class="col-6">
									<div class="row mb-5" v-if="appointment.startDate.split('T')[0]">
										<div class="col d-flex align-items-center">
											<div class="float-left">
												<i class="fas fa-calendar-alt fs-30-px mx-2"></i>
											</div>
											<div>
												<label for="appt_date" class="fw-bolder">{{ appointment.startDate.split("T")[0] }}</label>
												<h4 id="appt_date">
													{{ appointment.startDate.split("T")[1] }}
												</h4>
											</div>
										</div>
									</div>
									<div class="row mb-5">
										<div class="col d-flex align-items-center">
											<div class="float-left">
												<i class="fas fa-history fs-30-px mx-2"></i>
											</div>
											<div>
												<label for="patient_last_visit">Patient last visit: </label>
												<h4 id="patient_last_visit" v-if="appointment.lastVisitDate">
													{{ appointment.lastVisitDate.split("T")[0] }}
												</h4>
												<h4 id="patient_last_visit" v-else>No last visit</h4>
											</div>
										</div>
									</div>
									<div class="row mb-5">
										<div class="col d-flex align-items-center">
											<div class="float-left mx-1">
												<i v-if="appointment.insuranceStatus != 2" :class="`fas ${appointment.insuranceProvider ? 'fa-check-circle' : 'fa-times-circle'} fs-30-px mx-2 ${appointment.insuranceProvider ? 'color-green' : 'text-danger'}`"></i>
												<img v-else src="/odsp.png" width="35" alt="" title="ODSP" />
											</div>
											<div>
												<label for="patient_insurance_provider">
													Insurance:
													<!-- <i v-if="appointment.insuranceStatus != 2" class="far fa-edit mx-2 cursor-pointer fs-3" @click="openInsuranceCovModal"></i>
													<InlineSvg v-else class="cursor-pointer" src="\media\icons\duotone\Code\Info-circle.svg" /> -->
												</label>
												<h4 id="patient_insurance_provider" v-if="appointment.insuranceProvider">
													{{ appointment.insuranceProvider }} ({{ appointment.feeScheduleName }})
												</h4>
												<h4 id="patient_insurance_provider" v-else>Not insured ({{ appointment.feeScheduleName }})</h4>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<modal id="profileModal"
	 		:divisor="1.5"
			:show="showProfileModal"
			cancelText="Cancel" 
			:footer="false" 
			:showCancel="true"
			:title="`Edit Procedures for patient`" 
			okText="Save" 
			@close="closeProfileModal" 
			@ok="closeProfileModal" 
			@no="closeProfileModal">
			<PatientDNA :patientId="patientId" @closeProfileModal="closeProfileModal"></PatientDNA>			
	</modal>
	
</template>

<script>
import { defineComponent, ref } from "vue";
import { differenceInYears } from "date-fns";
import Modal from "@/components/Modal.vue";
import InlineSvg from "vue-inline-svg";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import PatientDNA from "@/components/settings/PatientDNA.vue";	

export default defineComponent({	
	components: { Modal, InlineSvg, PatientDNA },
	props: {
		appointment: { type: Object },
		clinicInfo: { type: Object },
		hasPatientDNAProp: { type: Boolean }
	},
	emits: ["historyChanged", "dnaProfileChanged", "patientDNAChanged"],
	methods: {		
		editPatientDNA() {
			this.patientId = this.appointment.patientId

			this.showProfileModal = true;
		},		
		closeProfileModal(saved = false) {
			this.showProfileModal = false;

			this.$emit("patientDNAChanged", saved);
		},
		async editDNAProfile() {		
			
			const healthProfilesData = await ApiService.get(`/ProcedureRules/HealthProfile`);		
			console.log(healthProfilesData);
			//add default clinic profile			
			healthProfilesData.data.unshift({ healthProfileId: null, profileName: "Office DNA" });
			this.healthProfiles = healthProfilesData.data.filter(x => x.dateDeleted == null);
			this.dnaEditMode = !this.dnaEditMode;			
		},
		async saveDNAProfile(){
			let res = await ApiService.post(`appointment/HealthProfile/${this.appointment.patientId}`, { healthProfileId: this.appointment.healthProfileId });
			if(res.status !== 200 && res.status !== 204){
				this.toast.error(codes.error)
			}
			else {
				console.log("dna profile changed");
				this.$emit("dnaProfileChanged");
			}
			this.appointment.healthProfileName = this.healthProfiles.find(x => x.healthProfileId === this.appointment.healthProfileId).profileName;
			this.dnaEditMode = false;
		},
		onTextAreaInput(e) {
			if (this.appointment.commentState && !e.target.value){
				this.appointment.commentState = null;				
			} else if (!this.appointment.commentState && e.target.value) {
				this.appointment.commentState = 1; //new 
			}
		},
		onNoteChange(e) {
			if (this.appointment.comments && this.appointment.comments !== this.previousCommentsValue) {
				const currentDateTime = new Date().toISOString().slice(0, 16).replace('T', ' ');
				this.appointment.comments += `\nEdited by: ${this.currentUser.email}  -  Time: ${currentDateTime}`;			
			}
		},
		copyAppointmentLink(clinicGuid) {
			let currentUrl = window.location.href;
			var url = new URL(currentUrl);
			url.search = ""
			url.searchParams.set("c", clinicGuid);
			let link = url.toString();
			let self = this;
			navigator.clipboard.writeText(link).then(
				function () {
					console.log("Async: Copying to clipboard was successful!");
					self.$root.showMessage("copied to clipboard!");
				},
				function (err) {
					console.error("Async: Could not copy text: ", err);
				}
			);
		},
		yearsDifference(date) {
			return differenceInYears(Date.now(), new Date(date));
		},
		closeInsuranceCovModal() {
			console.log("closing");
			this.showInsuranceCov = false;
		},
		openInsuranceCovModal() {
			this.showInsuranceCov = true;
		},
		async saveAppointmentComments() {
			//actually have to do something here
			let commentState = this.appointment.commentState ? Number(this.appointment.commentState) : null;			
			let res = await ApiService.post(`appointment/${this.appointment.appointmentId}/Comment`, { note: this.appointment.comments, commentState: commentState })
			if(res.status !== 200 && res.status !== 204){
				this.toast.error(codes.error)
			}
			else{
					//this.toast.success("Procedure was added to history");
					//have to refresh this page somehow
					this.$emit("historyChanged");
				}
			this.showAppointmentComments = false;
		},

		saveInsurancePrefs() {},
	},
	async mounted(){
		this.previousCommentsValue = this.appointment.comments;
	},
	data() {
		const store = useStore();
		const currentUser = store.getters.currentUser;
		return {
			showInsuranceCov: false,
			appointmentComments: null,
			showAppointmentComments: false,			
			currentUser,
			previousCommentsValue : null,
			healthProfiles: null,
			dnaEditMode: false,
			showProfileModal: false,
			healthProfileId: Number,
			patientId: Number,
			hasPatientProfileProcedures: false
		};
	},
});
</script>