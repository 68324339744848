<template>
	<!--end::Modal - New Card-->

	<div :class="`card`" style="width: 100%; height: 100%">
		<!--begin::Body-->
		<div class="card-body  py-1 pt-5 pe-15 d-flex justify-content-between">
			<div class="radio-inline">
				<label class="radio radio-rounded">
					<input type="radio" checked="checked" v-on:change="showActiveInActive(true)" name="radios15_1" />
					<span></span>
					Active
				</label>
				<label class="radio radio-rounded" style="padding-left: 7px;">
					<input type="radio" v-on:change="showActiveInActive(false)" name="radios15_1" />
					<span></span>
					All
				</label>
			</div>
			<div class="d-flex gap-2">
				<div>
					<!-- the greatest of all hacks to list Villanova explicitely. oh boy-->
					<button v-if="currentClinicName === 'Villanova'" class="btn btn-primary btn-sm"
						@click="showUploadTimeSheet">Upload Time Sheet</button>		
				</div>
				<div v-if="$store.getters.currentUser.isSuperAdmin">
					<button class="btn btn-primary btn-sm"
						@click="showGenerateScheduleModal">Generate Schedule</button>				
				</div>
			</div>
		</div>
		<div class="card-body row" style="width: 100%; height: 100%">
			<ag-grid-vue :defaultColDef="defaultColDef" :rowHeight="40" :columnDefs="columnDefs" :rowData="rowData"
				@grid-ready="onGridReady" @gridSizeChanged="gridSizeChanged" style="width: 100%; height: 100%"
				class="ag-theme-balham"> </ag-grid-vue>
		</div>
		<!--begin::Body-->
	</div>

		<!-- start modal -->
		<div class="modal fade" tabindex="-1" id="upload_timesheet_modal">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">
						Upload timesheet
					</h5>

					<!--begin::Close-->
					<div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal"
						aria-label="Close">
						<span class="svg-icon svg-icon-2x"></span>
						<inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
					</div>
					<!--end::Close-->
				</div>
				<div class="p-5">
					<label for="providerSelect">Upload CSV file: </label>
					<input type="file"   class="form-control" @change="handleFileUpload" />
					<p class="text-muted">Please upload daily totals <b>CSV</b> report</p>					
				</div>				
				<div class="modal-footer">
					<button class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
					<button class="btn btn-primary" @click="uploadTimesheet">Upload</button>
				</div>
			</div>
		</div>
	</div>
	<!-- end modal -->

	<!-- start modal -->
	<div class="modal fade" tabindex="-1" id="generate_schedule_modal">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">
						{{ `Generate Schedule` }}
					</h5>

					<!--begin::Close-->
					<div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal"
						aria-label="Close">
						<span class="svg-icon svg-icon-2x"></span>
						<inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
					</div>
					<!--end::Close-->
				</div>
				<div class="p-5">
					<label for="providerSelect">Select Provider: </label>
					<select class="float-end w-75" id="providerSelect" v-model="providerId">
						<option v-for="provider in providers" :key="provider.providerId" :value="provider.providerId">
							{{ provider.name }}
						</option>
					</select>
				</div>
				<div class="d-flex justify-content-between p-5">
					From: <input type="date" v-model="startGenerateDate">
					To: <input type="date" v-model="endGenerateDate" />
				</div>
				<div class="col-12 col-md-6 m-5  form-check ">
					<input class="form-check-input" :name="`recreateSchedule`" type="checkbox"
						v-model="recreateSchedule" />
					<label class="form-check-label">
						Recreate Schedule
					</label>
				</div>
				<div class="modal-footer">
					<button class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
					<button class="btn btn-primary" @click="generateSchedule">Go</button>
				</div>
			</div>
		</div>
	</div>
	<!-- end modal -->

	<!-- start modal -->
	<div class="modal fade" tabindex="-1" id="editProvider_modal">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">
						{{ `Edit provider for ${currentClinicName}` }}
					</h5>

					<!--begin::Close-->
					<div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal"
						aria-label="Close">
						<span class="svg-icon svg-icon-2x"></span>
						<inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
					</div>
					<!--end::Close-->
				</div>
				<edit-provider v-if="editProviderVisible" :selectedProvider="editProviderModalObject" :key="editProviderModalObject"
					:initialProvider="initialProvider" @closeEditProviderForm="closeEditProviderForm" />
			</div>
		</div>
	</div>

	<div class="modal fade" tabindex="-1" id="editProviderSchedule_modal">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">
						{{ `Edit provider schedule for ${currentClinicName}` }}
					</h5>

					<!--begin::Close-->
					<div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal"
						aria-label="Close">
						<span class="svg-icon svg-icon-2x"></span>
						<inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
					</div>
					<!--end::Close-->
				</div>
				<EditProviderSchedule v-if="editScheduleVisible" :selectedProvider="editScheduleModalObject"
					:key="editScheduleModalObject?.provider_id" @closeEditScheduleForm="closeEditScheduleForm" />

			</div>
		</div>
	</div>

	<div class="modal fade" tabindex="-1" id="editProviderWageHistory_modal">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">
						{{ `Edit provider wage history for ${currentClinicName}` }}
					</h5>

					<!--begin::Close-->
					<div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal"
						aria-label="Close">
						<span class="svg-icon svg-icon-2x"></span>
						<inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
					</div>
					<!--end::Close-->
				</div>
				<EditProviderWageHistory v-if="editWageHistoryVisible" :selectedProvider="editWageHistoryModalObject"
					:key="editWageHistoryModalObject?.provider_id"  @closeEditForm="closeEditProviderWageHistoryForm" />

			</div>
		</div>
	</div>

	<!-- end modal -->
	<!--end::Tables Widget 9-->
</template>

<script>
import { AgGridVue } from "ag-grid-vue3";
import { defineComponent, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import * as bootstrap from "bootstrap";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import EditProvider from "@/components/settings/EditProvider.vue";
import EditProviderSchedule from "@/components/settings/ProviderSchedule.vue";
import EditProviderWageHistory  from "@/components/settings/ProviderWageHistory.vue";
import { useStore } from "vuex";
import ProvidersActionButtonCellRenderer from "@/components/settings/ProvidersActionButtonCellRenderer.vue";
import TableAvatar from "@/components/settings/TableAvatar.vue"
import { useToast } from "vue-toastification";
import { codes } from "@/Codes/Codes";
import { ProviderTypeEnum } from "@/Models/data-contract/index";

export default defineComponent({
	name: "kt-widget-9",
	components: {
		AgGridVue,
		EditProvider,
		EditProviderSchedule,
		EditProviderWageHistory,
	},
	props: {
		widgetClasses: String,
	},
	data() {		
		return {
			toast: useToast(),
			list: [],
			modal: null,
			editProviderModal: null,
			pinModal: null,
			showEditProvider: false,
			editProviderVisible: false,
			editProviderModalObject: null,			
			editScheduleModal: null,
			generate_schedule_modal: null,
			editScheduleModalObject: null,
			editScheduleVisible: false,
			editWageHistoryVisible: false,
			showEditSchedule: false,
			showEditWageHistory: false,
			editWageHistoryModalObject: null,
			editWageHistoryModal: null,
			//ag-grid
			defaultColDef: null,
			columnDefs: null,
			rowData: null,
			providers: [{providerId : null, name : "All", isHygienist : true}],
			providerId: null,
			gridApi: null,
			columnApi: null,
			api: null,
			startGenerateDate: new Date().toISOString().split("T")[0],
			endGenerateDate: new Date().toISOString().split("T")[0],
			recreateSchedule: false,
		};
	},
	async mounted() {
		// this.loadData();
		//ugly hack for case where user is clinic admin, but also a provider then they shouldn't see this page
		const instanceInfo = this.$store.getters.currentInstanceInfo;
		if(instanceInfo.isProvider){
			this.toast.error("Access Denied. Please contact your clinic administrator.");
			return;
		}
		this.refreshTable();		  

		// editProvider_modal
		var modalElement = document.getElementById("editProvider_modal");
		this.editProviderModal = new bootstrap.Modal(modalElement);

		modalElement = document.getElementById("editProviderSchedule_modal");
		this.editScheduleModal = new bootstrap.Modal(modalElement);

		modalElement = document.getElementById("editProviderWageHistory_modal");
		this.editWageHistoryModal = new bootstrap.Modal(modalElement);

		
	},
	methods: {
		handleFileUpload(event) {
      		this.selectedFile = event.target.files[0];
    	},
		showUploadTimeSheet() {			
			var modalElement = document.getElementById("upload_timesheet_modal");
			this.uploadTimesheetModal  = new bootstrap.Modal(modalElement);
			this.uploadTimesheetModal.show();
		},
		showGenerateScheduleModal() {
			var modalElement = document.getElementById("generate_schedule_modal");
			this.generateScheduleModal = new bootstrap.Modal(modalElement);
			this.generateScheduleModal.show();
		},	
		async uploadTimesheet(){
			if (!this.selectedFile) {
        		alert("Please select a file first.");
        		return;
      		}
			const formData = new FormData();
			formData.append("file", this.selectedFile);

			try {
				const response = await ApiService.postData(
				"/Villanova/ImportItemCardsEntry",
				formData,
				{
					headers: {"Content-Type": "multipart/form-data"},
				});
				
				this.toast.success("File uploaded successfully. Payroll information has been updated");
      		} catch (error) {

				this.toast.error(`File upload failed: ${error.message}`);
				
				
      		}

			this.uploadTimesheetModal.hide();
		},
		async generateSchedule() {
			
			let providerSchedule = {
				startDate: this.startGenerateDate,
				endDate: this.endGenerateDate,
				recreateSchedule: this.recreateSchedule,
				providerId: this.providerId
			}
			console.log("providerSchedule", providerSchedule);
			await ApiService.post(`/Provider/TimeClockPunchGeneration`, providerSchedule)
			this.showGenerateScheduleGo = false;
			this.recreateSchedule = false;
			this.generateScheduleModal.hide();
		},
		async refreshTable() {
			try {
				this.defaultColDef = {
					resizable: true,
					sortable: true,
					filter: true,
					floatingFilter: true,
					suppressMenu: true,
					filterParams: {
						buttons: ["apply", "reset"],
						closeOnApply: true,
						suppressAndOrCondition: true,
					},
				};

				let self = this;
				this.columnDefs = [

					{ field: "provider_id", filter: true, headerName: "Provider Id" },
					{ field: "last_name", filter: true, headerName: "Last Name" },
					{ field: "first_name", filter: true, headerName: "First Name" },
					{ field: "suffix", filter: true, headerName: "Suffix" },
					{ field: "issecondaryprovider", filter: true, headerName: "Status" },
					// { field: "isnonperson", filter: true, headerName: "Non Person" },
					{ field: "providerType", filter: true, headerName: "Provider Type", valueFormatter: this.providerTypeFormatter },
					{
						field: "initials",
						filter: false,
						headerName: "User",
						cellRendererFramework: TableAvatar,
					},
					{ field: "baseWage", filter: true, headerName: "Base Wage" },
					{ field: "goalWage", filter: true, headerName: "Goal Wage", valueFormatter: (value) => { if (value.value) { return Math.round(value.value); } } },
					{ field: "goalWage", filter: true, headerName: "Per Hour Goal", valueFormatter: this.perHourGoalFormatter },
					{ field: "excludeFromCalendar", filter: true, headerName: "Exclude From Calendar" },
					{ field: "excludeFromAnalysis", filter: true, headerName: "Exclude From Chart" },
					{ field: "active", filter: true, headerName: "Active" },
					{ field: "notes", filter: true, headerName: "Notes" },
					{
						field: "edit",
						headerName: "Actions",
						cellRendererFramework: ProvidersActionButtonCellRenderer,
						resizable: false,
						filter: false,
						cellRendererParams: (params) => {
							return {
								isHygienist: params.data.providerType === 1,
								editProviderClicked: function (selectedData) {
									if (self.showEditProvider) {
										self.showEditProvider = !self.showEditProvider;
									}
									self.editProviderModalObject = selectedData;
									self.editProviderVisible = true;
									self.editProviderModal.show();
								},
								editScheduleClicked: function (selectedData) {
									if (self.showEditSchedule) {
										self.showEditSchedule = !self.showEditSchedule;
									}

									self.editScheduleModalObject = selectedData;
									self.editScheduleVisible = true;
									self.editScheduleModal.show();
									
								},
								editWageHistoryClicked: function (selectedData) {
									if (self.showEditWageHistory) {
										self.showEditWageHistory = !self.showEditWageHistory;
									}

									self.editWageHistoryModalObject = selectedData;
									self.editWageHistoryVisible = true;
									self.editWageHistoryModal.show();								
								},
							};
						}
					},
				];
				let providersList = await ApiService.query(`Provider/Details?activeOnly=true`);
				if (providersList.status !== 200) {
					this.toast.error(codes.error)
				}
				providersList = providersList.data;

				for (let i = 0; i < providersList.length; i++) {
					providersList[i].edit = providersList[i];
					providersList[i].initials = (providersList[i].userFirstName || "").charAt(0) + "" + (providersList[i].userLastName || "").charAt(0);
				}

				providersList.forEach((element) => {
					element.issecondaryprovider = element.issecondaryprovider == 0 ? "Primary" : "Secondary";
					//  element.isnonperson = element.isnonperson ? "Yes" : "No";
					element.baseWage = element.baseWage == null ? null : Math.round(element.baseWage);
					//element.goalWage = element.goalWage == null ? null : Math.round(element.goalWage);
					element.excludeFromCalendar = element.excludeFromCalendar ? "Yes" : "No";
					element.excludeFromAnalysis = element.excludeFromAnalysis ? "Yes" : "No";
					element.isHygienist = element.isHygienist ? "Yes" : "No";
					element.isActive = element.isActive;
				});
				console.log("providersList", providersList);
				this.providers = [
					{ providerId: null, name: 'All' },
					...providersList.filter(p => p.providerType === 1).map(x => ({ providerId: x.provider_id, name: x.first_name }))];
				this.rowData = providersList;
			} catch (e) {
				this.toast.error(codes.error)
			}
		},
		closeEditProviderForm() {
			this.refreshTable();
			this.editProviderVisible = false;
			this.editProviderModal.hide();
		},
		closeEditScheduleForm() {
			this.refreshTable();
			this.editScheduleVisible = false;
			this.editScheduleModal.hide();
		},
		closeEditProviderWageHistoryForm() {
			this.refreshTable();
			this.editWageHistoryVisible = false;
			this.editWageHistoryModal.hide();
		},
		providerTypeFormatter(value) {
			switch (value.value) {
				case ProviderTypeEnum.Other:
					return "";
					break;
				case ProviderTypeEnum.Hygienist:
					return "Hygienist";
					break;
				case ProviderTypeEnum.Doctor:
					return "Doctor";
					break;
				default:
					return "";
					break;
			}
		},
		perHourGoalFormatter(value) {
			if (value.value) {
				return Math.round(value.value / this.$store.getters.currentClinicInfo.goalPercentage);
			}
		},
		showActiveInActive: async function (inActive) {
			let providersList = await ApiService.query(`Provider/Details?activeOnly=${inActive}`);
			if (providersList.status !== 200) {
				this.toast.error(codes.error)
			}
			providersList = providersList.data;

			for (let i = 0; i < providersList.length; i++) {
				providersList[i].edit = providersList[i];
				providersList[i].initials = (providersList[i].userFirstName || "").charAt(0) + "" + (providersList[i].userLastName || "").charAt(0);
			}

			providersList.forEach((element) => {
				element.issecondaryprovider = element.issecondaryprovider == 0 ? "Primary" : "Secondary";
				//	element.isnonperson = element.isnonperson ? "Yes" : "No";
				element.baseWage = element.baseWage == null ? 0 : Math.round(element.baseWage);
				//element.goalWage = element.goalWage == null ? 0 : Math.round(element.goalWage);
				element.excludeFromCalendar = element.excludeFromCalendar ? "Yes" : "No";
				element.excludeFromAnalysis = element.excludeFromAnalysis ? "Yes" : "No";
				element.isHygienist = element.isHygienist ? "Yes" : "No";
				element.isActive = element.isActive;
			});
			this.gridApi.setRowData(providersList);
		},

		onGridReady(params) {
			this.api = params.api;
			this.gridApi = params.api;
			this.columnApi = params.columnApi;
		},
		gridSizeChanged() {
			this.api.sizeColumnsToFit();
		},
		getSelectedRows() {
			try {
				if (this.showEditProvider) {
					this.showEditProvider = !this.showEditProvider;
				}
				const selectedNodes = this.gridApi.getSelectedNodes();
				const selectedData = selectedNodes.map((node) => node.data);
				console.log("data", selectedData);
				// console.log(`Selected nodes: ${selectedData}`);
				if (!selectedNodes[0]) {
					throw "Select a provider first.";
				}
				this.editProviderModalObject = selectedData[0];
				this.editProviderVisible = true;
				this.editProviderModal.show();
			} catch (err) {

			}
		},
		async loadData() {
			try {
				let res = await ApiService.query(`Provider/Details?activeOnly=true`);

				if (res.status !== 200) {
					this.toast.error(codes.error)
				}

				let list = res.data;

				list.forEach((element) => {
					element.issecondaryprovider = element.issecondaryprovider == 0 ? "Primary" : "Secondary";
					//  element.isnonperson = element.isnonperson ? "Yes" : "No";
					element.baseWage = element.baseWage == null ? 0 : Math.round(element.baseWage);
					// element.goalWage = element.goalWage == null ? 0 : Math.round(element.goalWage);
					element.excludeFromCalendar = element.excludeFromCalendar ? "Yes" : "No";
					element.excludeFromAnalysis = element.excludeFromAnalysis ? "Yes" : "No";
					element.isHygienist = element.isHygienist ? "Yes" : "No";
					element.isActive = element.isActive;
				});

				this.list = list;
			} catch (e) {
				this.toast.error(codes.error)
			}
		},		
	},
	setup() {
		setCurrentPageTitle("Providers");
		const checked = ref(false);
		const store = useStore();
		const initialProvider = store.getters.currentUser;
		const currentClinicName = initialProvider.currentClinic.name;

				

		return {
			checked,
			initialProvider,
			currentClinicName,
		};
	},
});
</script>

<style lang="scss" scoped>
.mr-3 {
	margin-right: 3rem !important;
}

@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-balham.css";
</style>
