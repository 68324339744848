<template>
	<td v-if="opted">
		<span v-if="task.calculationType === 7 && task.procedureRuleTypeId == 35">
			<p>
				<i v-if="task.isRecommended" class="fa-solid fa-star" title="Recommended by RNA" style="padding-right:5px; color:#50cd89"></i>
				{{ `${task.name}` }}
				{{ `${task.description && showDescription ? ": " + task.description : ""}` }}
			</p>
			<div :class="`${item.show ? 'pl-5 sub-p' : ''}`" v-for="(item, sindex) in appointment.selectedRecommendations.filter((x) => x.calculationType == 7 && x.procedureRuleTypeId != 35)" :key="`y_${sindex}`">
				<p class="pl-5">{{ item.name }} x{{ item.quantity }}</p>
				<!-- <p class="pl-5" v-if="item.selectedTeeth && item.selectedTeeth.length > 0">teeth: <span v-for="t, zindex in item.selectedTeeth" :key="zindex" > {{t}} {{zindex == item.selectedTeeth.length -1 ? '':', '}} </span></p> -->
			</div>
			<div :class="`${item.show ? 'pl-5 sub-p' : ''}`" v-for="(item, sindex) in appointment.selectedRecommendations.find((x) => x.procedureRuleTypeId == 35).procedureOptions.filter(x=> x.dentrix)" :key="`y_${sindex}`">
				<p class="pl-5">{{ item.name }}</p>
			</div>
		</span>
		<span v-else-if="appointment.selectedRecommendations.find((x) => x.calculationType == 8) && appointment.selectedRecommendations.find((x) => x.calculationType == 8).parentProcedureRuleTypes.find((y) => y == task.procedureRuleTypeId) != null">
			<p>
				<i v-if="task.isRecommended" class="fa-solid fa-star" style="padding-right:5px; color:#50cd89" title="Recommended by RNA"></i>
				{{ `${task.name}` }}
				{{ `${task.description && showDescription ? ": " + task.description : ""}` }}				
			</p>
			<p class="pl-5 sub-p" v-for="(child, sindex) in task.children" :key="sindex">
				<span>
					{{ child.name }}
				</span>
			</p>
		</span>
		<span v-else>
			<p>
				<i v-if="task.isRecommended" class="fa-solid fa-star" style="padding-right:5px; color:#50cd89" title="Recommended by RNA"></i>
				{{ `${task.name}` }}
				{{ `${task.description && showDescription ? ":" + task.description : ""}` }}				
			</p>
			<div :class="`${item.show ? 'pl-5 sub-p xxx' : ''} ${task.calculationType !== 4 ? '' : 'display-none'}`" 
				v-for="(item, sindex) in task.procedureOptions" :key="`y_${sindex}`">
				<p  v-show="task.calculationType !== 2"  class="pl-5" v-if="item.show && task.calculationType !== 4 && (task.calculationType == 1 && task.procedureOptions && task.procedureOptions.length > 0)">{{ item.name }} {{ item.description && showDescription ? ": "+item.description: "" }} {{ item.teeth ? `(#${item.teeth[0]})` : "" }}</p>
			</div>
			<p v-if="task.selectedTeeth && task.selectedTeeth.length > 0" @click="editSealantsTeeth(task)" title="Click to edit teeth" class="cursor-pointer">
				Selected teeth:
				<span v-for="(t, index) in task.selectedTeeth" :key="t"> {{ t }}{{ index < task.selectedTeeth.length - 1 ? ", " : "" }} </span>
			</p>
			<div class="pl-5 sub-p cursor-pointer" v-show="appointment.insuranceStatus == 2 && (task.procedureRuleTypeId == 8 || task.procedureRuleTypeId == 30 || task.procedureRuleTypeId == 14)" v-for="criteria, index in task.selectedCriteria" :key="index" @click="editOdspCriteria(task)">
				<p class="pl-5"> {{odspCriteria[Number(criteria)]}} </p>
			</div>
		</span>		
	</td>
	<td v-else>
		<div class="d-flex justify-content-between">
			<span v-if="task.calculationType === 7 && task.procedureRuleTypeId == 35">
				<p :class="`head-p`"><i v-if="task.isRecommended" class="fa-solid fa-star" style="padding-right:5px; color:#50cd89" title="Recommended by RNA"></i>{{ `${task.name}` }}{{ `${task.description && showDescription ? ": "+task.description : ""}` }}</p>
				<p style="display: none;" :class="`pl-20px pl-5 sub-p hide-${task.procedureRuleTypeId}`" v-for="(item, index) in type7s" :key="`y_${index}`"><i v-if="item.isRecommended" class="fa-solid fa-star" style="padding-right:5px; color:#50cd89" title="Recommended by RNA"></i>{{ item.name }}{{ `${item.description && showDescription ? ": "+item.description : ""}` }}</p>
				
			</span>			
			<span v-else-if="task.calculationType !== 7">
				<p :class="`${task.procedureOptions && task.procedureOptions.length > 0 ? 'head-p' : ''}`"><i v-if="task.isRecommended" class="fa-solid fa-star" style="padding-right:5px; color:#50cd89" title="Recommended by RNA"></i>{{ `${task.name}` }}{{ `${task.description && showDescription ? ": "+task.description : ""}` }}</p>
				<p style="display: none;" :class="`pl-20px pl-5 sub-p hide-${task.procedureRuleTypeId}`" v-for="(item, index) in task.procedureOptions" :key="`y_${index}`">{{ item.name }}{{ item.description && showDescription ? `: ${item.description}`:`` }} </p>				
			</span>
			<div class="text-nowrap">
			<span v-if="task.isPrePlanned" class="badge badge-primary" style="height:22px;width:60px;padding:0.5em"
			 :title="prePlanApplied ? 'Select child option to apply plan' : ''" > {{ prePlanApplied ? "Select" : "Planned" }} </span>
			<span v-if="task.treatmentDueInfo.isDue" class="badge badge-danger" 
				@click="editDueProcedure(task)"
				:style="`cursor:pointer;height:22px;width:35px;padding:0.5em; ${task.treatmentDueInfo.needsExamButNoExamDue ? 'background-color:lightgrey;text-decoration: line-through;' : ''}`" 
				:title="(task.treatmentDueInfo.needsExamButNoExamDue ? 'Due but no accompanying exam is due. ': '') + (task.treatmentDueInfo.lastTreatmentDate ? `Last done: ${dateFormat(new Date(task.treatmentDueInfo.lastTreatmentDate),'yyyy-MMM-dd')}` : 'Last done: no record of having been done')">Due</span>
			</div>
		</div>
	</td>
</template>

<script>
	import { format } from "date-fns";
	export default {
		data(){
			return {
				showDescription: true,
			}
		},
		props: { 
			odspCriteria: { required: true }, 
			type7s: { required: true }, 
			appointment: { type: Object, required: true }, 
			task: { type: Object, required: true }, 
			opted: { type: Boolean, required: true } ,
			prePlanApplied: { type: Boolean, required: true }
		},
		methods: {
			editSealantsTeeth(task) {
				this.$emit("editSealants");
			},
			editOdspCriteria(task){
				this.$emit("editOdspCriteria")
			},
			editDueProcedure(task){
				this.$emit("editDueProcedure");
			},
			dateFormat(date, formatString) {
      			return format(date, formatString);
    		}
		},
		beforeMount(){

			let show = this.$store.getters.currentClinicInfo.dmsType?.toLowerCase() != "abel";
			this.showDescription = show;
		}
	};
</script>
