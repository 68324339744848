<template>
  <!-- start modal -->
   <!-- Add new profile -->
  <div class="modal fade" tabindex="-1" id="profile_modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ `Add Health Profile for ${this.clinicName}` }}
          </h5>

          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="svg-icon svg-icon-2x"></span>
            <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
          </div>
          <!--end::Close-->
        </div>
        <edit-health-profile
          v-if="showCreateProfile"
          :selectedProfile="null"
          @closeAddProfileForm="closeAddProfileForm"
/>
      </div>
    </div>
  </div>
  <!-- end modal -->

  <!--end::Modal - New Card-->

  <div :class="`card ${widgetClasses}`" style="width:100%;height:100%;">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1">Health Profiles</span>

        <!--<span class="text-muted mt-1 fw-bold fs-7">Over 500 members</span>-->
      </h3>

      <div
        class="card-toolbar mr-3 "
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        data-bs-trigger="hover"
        title="Click to add a new health profile"
      >
        
        <a @click="newProfile()" class="btn btn-sm btn-light-primary add-user button-font-shadow shadow">
          <span class="svg-icon svg-icon-3">
            <inline-svg src="/media/icons/duotone/Communication/Add-user.svg" />
          </span>
          New Health Profile
        </a>
      </div>
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body py-3 row">
      <!--begin::Table container-->
      <div class="table-responsive row">
        <!--begin::Table-->
        <div class="row">
          <ag-grid-vue
            :defaultColDef="defaultColDef"
            :columnDefs="columnDefs"
            :rowData="rowData"
            :rowHeight="35"
            @grid-ready="onGridReady"
            @gridSizeChanged="gridSizeChanged"
            style="width: 100%; height: 100%;"
            class="ag-theme-balham"
          >
          </ag-grid-vue>
        </div>
        <!--end::Table-->
      </div>
      <!--end::Table container-->
    </div>
    <!--begin::Body-->
  </div>

  <!-- start modal -->
   <!-- Edit existing profile -->
  <div class="modal fade" tabindex="-1" id="editProfile_modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Edit {{ selectedProfile?.profileName }}
          </h5>

          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="svg-icon svg-icon-2x"></span>
            <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
          </div>
          <!--end::Close-->
        </div>
        <edit-health-profile
          v-if="editProfileVisible"          
          :selectedProfile="selectedProfile"
          @closeEditProfileForm="closeEditProfileForm"
          :key="selectedProfile?.healthProfileId"
        />
      </div>
    </div>
  </div>
  <!-- end modal -->
  <!-- start modal -->
   <!-- Delete existing profile-->
  <div class="modal fade" tabindex="-1" id="deleteProfile_modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ `Deactivate Profile` }}
          </h5>

          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="svg-icon svg-icon-2x"></span>
            <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
          </div>
          <!--end::Close-->
        </div>
        <div class="modal-body">
          <p>Are you sure you want to deactivate this profile?</p>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <button class="btn btn-danger" @click="deactivateProfile(selectedProfile?.healthProfileId)">Confirm</button>
        </div>
      </div>
    </div>
  </div>
  <!-- end modal -->

  <!--end::Tables Widget 9-->
</template>

<script>
import { AgGridVue } from "ag-grid-vue3";
import { defineComponent, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import * as bootstrap from "bootstrap";
// import CreateEditUser from "@/components/settings/CreateEditUser.vue";
import EditDeleteProfile from "@/components/settings/EditDeleteProfile.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import EditHealthProfile from "@/components/settings/EditHealthProfile.vue";
import {useToast} from "vue-toastification";
import {codes} from "@/Codes/Codes";

var globalProfileName = "Office DNA";

export default defineComponent({
  name: "kt-widget-9",
  components: {
    EditHealthProfile,
    AgGridVue,
  },
  props: {
    widgetClasses: String,
  },
  data() {
    return {
      toast: useToast(),
      list: [],
      modal: null,
      editProfileModal: null,
      deleteProfileModal: null,
      showCreateProfile: false,
      editProfileVisible: false,
      selectedProfile: null,
      defaultColDef: null,
      columnDefs: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      api: null,
      currentClinicName: null,
      profileName: "",
      healthProfileId: null,
    };
  },
  computed: {
    clinicName() {      
      const store = useStore();
      const initialUser = store.getters.currentUser;
      return initialUser.currentClinic.name;
    },
    selectedProfileName() {
      return this.data.profileName;
    },
    selectedProfileId() {
      return this.data.healthProfileId;
    },
  },
  async beforeMount() {
    this.loadData();
  },
  async mounted() {
    var modalElement = document.getElementById("profile_modal");
    this.modal = new bootstrap.Modal(modalElement);
    if (modalElement) {
      var self = this;
      modalElement.addEventListener("hidden.bs.modal", function(event) {
        self.showCreateEditUser = false;
      });
    }
    // editProfile_modal
    var modalElement = document.getElementById("editProfile_modal");
    this.editProfileModal = new bootstrap.Modal(modalElement);

    // deleteProfile_modal
    var deleteModalElement = document.getElementById("deleteProfile_modal");
    this.deleteProfileModal = new bootstrap.Modal(deleteModalElement);
  },
  methods: {
    async loadData() {
      try{
        this.defaultColDef = {
          resizable: true,
          sortable: true,
          filter: true,
          floatingFilter: true,
          suppressMenu: true,
          filterParams: {
            buttons: ["apply", "reset"],
            closeOnApply: true,
            suppressAndOrCondition: true,
          },
        };

        let self = this;
        this.columnDefs = [   
          { field: "profileName", filter: true },
          { field: "dateCreated", 
            filter: true, 
            valueFormatter: params => params.value ? new Date(params.value).toISOString().split('T')[0] : '' },
          // { field: "dateDeleted", 
          //   filter: true, 
          //   valueFormatter: params => params.value ? new Date(params.value).toISOString().split('T')[0] : '' }, 
          { field: "edit",
            headerName: "Actions",
            cellRendererFramework: EditDeleteProfile,
            resizable: false,
            filter: false,
            maxWidth: 187,
            cellRendererParams: {
              isOnlyEdit: false ,             
              clicked: function() {
                if (self.showCreateProfile) {
                  self.showCreateProfile = !self.showCreateProfile;
                }
                self.selectedProfile = {
                    profileName: this.data.profileName,
                    healthProfileId: this.data.healthProfileId,
                };
                console.log("selectedProfile", self.selectedProfile);
                self.editProfileVisible = true;
                self.$nextTick(() => {
                  self.editProfileModal.show();
                });
              },
              deleteClicked: function() {
                self.selectedProfile = {
                    profileName: this.data.profileName,
                    healthProfileId: this.data.healthProfileId,
                };
                console.log("selectedProfile", self.selectedProfile);
                //self.editProfileVisible = true;
                self.$nextTick(() => {
                  self.deleteProfileModal.show();
                });
              }
            },
          },
        ];
          console.log("refresh table");
          const globalProfile = {
            healthProfileId: 0,
            profileName: globalProfileName
          }
        let profileList = await ApiService.get("/ProcedureRules/HealthProfile");
        if(profileList.status !== 200){
          this.toast.error(codes.error)
        }
        profileList = profileList.data;
        profileList = profileList.filter(x => x.dateDeleted == null);
        profileList.unshift(globalProfile);
        this.rowData = profileList;
      }catch (e) {
        //console.log(e);
        this.toast.error(codes.error)
      }
    },
    async deactivateProfile(profileId) {
      console.log("profileId", profileId);
      let healthProfile = await ApiService.delete(`/ProcedureRules/HealthProfile/${profileId}`, {});
      if (healthProfile.status == 200) {
        this.closeDeleteModal();
      }
    },
    closeAddProfileForm() {
      this.refreshTable();
      this.modal.hide();
      this.showCreateProfile = false;
    },
    closeEditProfileForm() {
      this.refreshTable();
      this.editProfileVisible = false;
      this.editProfileModal.hide();
    },
    closeDeleteModal() {      
      this.refreshTable();
      this.showDeleteProfileModal = false;
      this.deleteProfileModal.hide();
    },
    onGridReady(params) {
      this.api = params.api;
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    gridSizeChanged() {
      this.api.sizeColumnsToFit();
    },
    getSelectedRows() {
      try {
        if (this.showCreateEditUser) {
          this.showCreateEditUser = !this.showCreateEditUser;
        }
        const selectedNodes = this.gridApi.getSelectedNodes();
        const selectedData = selectedNodes.map((node) => node.data);
        console.log("data", selectedData);
        console.log(`Selected nodes: ${selectedData}`);
        if (!selectedNodes[0]) {
          throw "Select a user first.";
        }
        this.selectedProfile = selectedData[0];
        this.editProfileVisible = true;
        this.editProfileModal.show();
      } catch (err) {}
    },
    newProfile() {
      this.modal.show();
      this.showCreateProfile = true;
    },
    onSubmitCompleted() {
      console.log("hitting here");
      this.loadData();

      this.modal.hide();
    },
    refreshTable() {
      this.loadData();
    },
  },
  setup() {
    setCurrentPageTitle("DNA Profile Management");
    const checked = ref(false);
    return {
      checked,
    };
  },
});
</script>

<style lang="scss" scoped>
.mr-3{
  margin-right: 3rem !important;
}

@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-balham.css";
</style>
