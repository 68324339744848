

import { defineComponent } from "vue";
import AlertComponent from "@/components/atoms/Alert.vue";
import { codes } from "@/Codes/Codes";
import { useToast } from "vue-toastification";
import { AppointmentEndpoints } from "@/core/api/AppointmentEndpoints"
import ApiService from "@/core/services/ApiService";
import { format, add } from "date-fns";
import { DateHelper } from "@/Utilities/DateHelper";
import Modal from "@/components/Modal.vue";
import { ProcedureRuleType } from "@/ProcedureRules/ProductionCalculationType";

export default defineComponent({
    name: "PreviewAppointmentModal",
    components: { AlertComponent, codes, Modal },
    data() {
        return {
            toast: useToast(),            
            appointmentId: this.$route.params.id,
            procedures: null as any,
            suggestedDate: null as Date | null,
            timeFrameInMonths: '6',
            comments: '',
            intialDate: null as string | null,    
            otherProcedures: [] as any,  
            additionalProcedure: null as number | null,                 
            showAdd: false as boolean,
        }
    },
    props: {
        displayAlert: { type: Boolean },        
        allPossibleProcRules: {type: Array<ProcedureRuleType>, required: true},
        patientId: { type: Number, required: true },
        profileId: { type: Number, required: true }
    },
    methods: {
        addProcedure(){
            if (this.additionalProcedure){
                let add = this.otherProcedures.find(p => p.procedureRuleTypeId === this.additionalProcedure);
                if (add){
                    console.log("adding", add);
                    this.procedures.push({
                        procedureRuleTypeId: add.procedureRuleTypeId, 
                        procedureName: add.procedureName, 
                        isChecked:true, 
                        dueDate: null,
                        durationInMinutes: add.durationInMinutes
                    })
                }
            }
            this.showAdd = false;
        },
        selectProcedure(e){
            // get the index of the array
            var id = e.target.getAttribute('data-id');
            // select/unselect the array item
            this.procedures[id].isChecked = e.target.checked;
            // calculate the duration            
            
        },
        dateFormat(date, formatString) {
            return format(date, formatString);
        },
        async updateDate(e) {
            this.intialDate = format(add(new Date(), { months: e.target.value }), "yyyy-MM-dd");
            await this.getAppointmentPreview();
        },
        async getAppointmentPreview() {          
            try {
                const prevData = await AppointmentEndpoints.GetNextAppointmentPreview(this.appointmentId, this.intialDate);
                if (prevData.status == 200) {
                    const previewAppt = prevData.data;
                                  
                    let procs = previewAppt.procedures.map(p => ({
                        ...p,                        
                        isChecked: p.isSelected
                    }));
                    
                    const procedureRuletypes = Object.assign({}, ...this.allPossibleProcRules.map((p) => ({[p.procedureRuleTypeId]: p})));     
                    
                    let firstItems = procs.filter(p => procedureRuletypes[p.procedureRuleTypeId].scheduleOrderBeginning !== null)
                    DateHelper.sortArrayByFunction(firstItems, (item => {return procedureRuletypes[item.procedureRuleTypeId].scheduleOrderBeginning}), "asc");
                    let lastItems = procs.filter(p => procedureRuletypes[p.procedureRuleTypeId].scheduleOrderEnd !== null)
                    DateHelper.sortArrayByFunction(lastItems, (item => {return procedureRuletypes[item.procedureRuleTypeId].scheduleOrderEnd}), "asc");
                    let middleItems = procs.filter(p => procedureRuletypes[p.procedureRuleTypeId].scheduleOrderEnd === null && procedureRuletypes[p.procedureRuleTypeId].scheduleOrderBeginning === null)
                    procs = [...firstItems,...middleItems,...lastItems]; 
                    this.otherProcedures = previewAppt.otherProcedures.map(p => ({
                        ...p,
                        isChecked:false                        
                    }));
                    DateHelper.sortArrayByProperty(this.otherProcedures, "name", "asc");
                    console.log("this.allPossibleProcRules", this.allPossibleProcRules);
                    console.log("procedureRuleTypes", this.otherProcedures);
                    
                    console.log("procs", procs);
                    //try to carry the time so they don't lose it if they already entered it
                    if (this.procedures){
                        for(let proc of this.procedures){
                            let newProc = procs.find(p => p.procedureName === proc.procedureName);
                            if (newProc){
                                newProc.durationInMinutes = proc.durationInMinutes;
                                newProc.isChecked = proc.isChecked;
                            }
                        }
                    }

                    this.procedures = procs;                      
                    this.suggestedDate = DateHelper.getDatePortionFromString(previewAppt.appointmentDate);
                    this.comments = previewAppt.comments;

                    //previewAppt.selectedTimeframe is always what has been saved, so if the user changes it, we don't want to take it 
                    if (this.intialDate === null){ //this means its the first time the page is loaded
                        
                        //overwrite the timeframe with what was saved
                        this.timeFrameInMonths = previewAppt.selectedTimeframe ?? this.timeFrameInMonths ?? 6;
                    } else {
                        //this means the user has changed the timeframe so let vue reactivity handle it. 
                        //this.timeFrameInMonths is already set to the new value
                    }
                } 
                else 
                {
                    // Error cases will be handled by a flag
                    this.toast.error(codes.error);
                    return;
                }
            } 
            catch (e) {
                this.toast.error(codes.error);
                throw e;
            }
        },
        getAppointmentNext(){
            return {
                timeFrameInMonths: Number(this.timeFrameInMonths),
                procedures: this.procedures.filter(p => p.isChecked).map(p => ({
                    procedureRuleTypeId: p.procedureRuleTypeId, minutes: p.durationInMinutes
                })),
                comments: this.comments
            };
        },
    },
    computed: {
        treatmentTime: function(){
            if (this.procedures)
            {
                return this.procedures.filter(p => p.isChecked).reduce((total, num) => total + num.durationInMinutes, 0);
            } else {
                return 0;
            }
            
        },
        calculateAppointmentLength: function() {
            if (!this.procedures) {
                return 0;
            }
            
            // round the values
            return Math.ceil(this.treatmentTime / 10.0) * 10;

        }
    },
    mounted() {
        this.getAppointmentPreview();
    }

});

