
import ApiService from "@/core/services/ApiService";
import ApexCharts from 'apexcharts';
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useToast } from "vue-toastification";
import { codes } from "@/Codes/Codes";
import { DateHelper } from "@/Utilities/DateHelper";


export default defineComponent({
  name: "App",
  data() {
    return {
      GeneralInformation: [],
      HygieneResourcing: [],
      ProcedureDistribution: [],
      graphData: [],
      loading: false,
      loader: false,
      toast: useToast(),
    };
  },
  methods: {
    async fetchAssessmentReport() {
      try {
        let assessmentReport = await ApiService.get("Report", "Assessment");
        this.graphData = assessmentReport.data.filter((x: any) => x["MonthDate"]);
        assessmentReport.data = assessmentReport.data.filter((x: any) => !x["MonthDate"]);
        this.GeneralInformation = assessmentReport.data.filter((x: any) => Object.keys(x).length == 1);
        this.HygieneResourcing = assessmentReport.data.filter((x: any) => Object.keys(x).length > 1 && Object.keys(x).length < 3);
        this.ProcedureDistribution = assessmentReport.data.filter((x: any) => Object.keys(x).length > 2);
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.toast.error(codes.error);
        throw e;
      }
    },
    formatCamelCase(str: any) {
      return str.replace(/([a-z])([A-Z0-9])/g, '$1 $2').replace(/([0-9])([A-Z])/g, '$1 $2');
    },
    renderChart() {
      const element = this.$refs.chart as any;
      if (!element || !this.graphData || this.graphData.length === 0) {
        return;
      }
      const height = parseInt(window.getComputedStyle(element).height);

      const years: number[] = [...new Set(this.graphData.map((data: any) => DateHelper.getDatePortionFromString(data.MonthDate).getFullYear()))];
      const monthsName: string[] = this.graphData.map((data: any) => {
        const date = DateHelper.getDatePortionFromString(data.MonthDate);
        return `${date.toLocaleString('en-US', { month: 'short' })} ${date.getFullYear()}`;
      });
      const uniqueMonthsName: string[] = [...new Set(monthsName)];

      interface ModifiedData {
        name: string;
        data: (number | null)[];
      }

      const modifiedDataset: ModifiedData[] = [];

      for (let year of years) {
        modifiedDataset.push({ name: year.toString(), data: new Array<number | null>() });
      }

      for (let yearIndex = 0; yearIndex < years.length; yearIndex++) {
        for (let monthName of uniqueMonthsName) {
          const existingData = this.graphData.find((data: any) => {
            const dataDate = DateHelper.getDatePortionFromString(data.MonthDate);
            const dataMonthName = `${dataDate.toLocaleString('en-US', { month: 'short' })} ${dataDate.getFullYear()}`;
            return dataMonthName === monthName && dataDate.getFullYear() === years[yearIndex];
          }) as any;
          modifiedDataset[yearIndex].data.push(existingData ? existingData.NumberOfActivePatients : null);
        }
      }

      const options = {
        series: modifiedDataset,
        chart: {
          type: 'bar',
          stacked: false,
          height: height,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 5,
        },
        noData: {
          text: "No data available for selected range",
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: "black",
            fontSize: "14px",
          },
        },
        legend: {
          show: true,
          showForSingleSeries: false,
          showForNullSeries: true,
          showForZeroSeries: true,
          position: "bottom",
          horizontalAlign: "center",
          floating: false,
          fontSize: "14px",
          fontFamily: "Helvetica, Arial",
          fontWeight: 400,
          itemMargin: {
            horizontal: 5,
            vertical: 0,
          },
          onItemClick: {
            toggleDataSeries: true,
          },
          onItemHover: {
            highlightDataSeries: true,
          },
        },
        xaxis: {
          type: "category",
          categories: uniqueMonthsName,
        },
        colors: ['#3cb44b'],
      };


      const chart = new ApexCharts(element, options);
      chart.render();
    },
    async getScheduleDetail() {
      try {

        let params = {}
        var res = await ApiService.query("Report/Assessment/ScheduleDetail", { params, responseType: "blob" });
        //download file to user's computer in res.data
        if (res.data) {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `ScheduleDetail.xlsx`);
          document.body.appendChild(link);
          link.click();
        }
      }
      catch (e) {
        this.toast.error(codes.error);
        throw e;
      }

    }
  },
  async mounted() {
    setCurrentPageTitle("Assessment");
    this.loading = true;
    await this.fetchAssessmentReport();
    this.renderChart();
    this.loading = false;
  },
});
