
import { defineComponent, shallowReactive } from "vue";
import ApiService from "@/core/services/ApiService";
import { useToast } from "vue-toastification";
import { codes } from "@/Codes/Codes";
import { DateHelper } from "@/Utilities/DateHelper";

export default defineComponent({
	components: {},
	props: {
		profileId: {
			type: Number,
			required: false
		},
		procedureRuleTypeId: {
			type: Number,
			required: false
		},
		patientId: {
			type: Number,
			required: true
		},
		patientAge: {
			type: Number,
			required: false
		}
	},
	data() {
		return {
			healthProfileId: null as number | null,
			toast: useToast(),
			settingsData: null as any,
			settingsDataOld: null as any,
			saved: false as boolean,
			adultMinAgeOptions: [16,17,18,19,20,21,22,23,24,25],
			healthProfiles: [] as any,
			procedureRulesInParent: [] as any,
			profileName: ""
		};
	},
	created() {
		this.healthProfileId = this.profileId ? Number(this.profileId) > 0 ? Number(this.profileId) : null : null;
	},
	async mounted() {
		console.log("Received Props:", this.profileId, this.procedureRuleTypeId, this.patientId, this.patientAge);
		await this.getSettings();		
	},
	computed: {
		enableSave() {
			for (const grp of this.settingsData) {
				for (const procedureRule of grp.data) {
					for (const rule of procedureRule.rules) {
						if (this.isInError(procedureRule, rule)) {
							return false;
						}
					}
				}
			}
			//console.log(JSON.stringify(this.settingsDataOld), JSON.stringify(this.settingsData))
			return JSON.stringify(this.settingsDataOld) !== JSON.stringify(this.settingsData);
		},
		selectedPatientId() {
			let patientValue = this.patientId !== undefined && this.patientId !== null 
			? Number(this.patientId) 
			: Number(this.$route.params.patientId);

			return patientValue;
		},		
		selectedHealthProfile() {
			return this.profileId != undefined ? this.profileId : null;
			// let profileValue = Number(this.profileId);

			// return profileValue;
		}
	},
	methods: {
		getDisplayClass(field, grp) {
			return field.isActive ? grp.color + "_darker" : 'lightgrey';			
  		},
		toggleFromProfile(procedureRule: ProcedureRule, active) {
			for (let rule of procedureRule.rules) {
				rule.isActive = !active;
			}

			procedureRule.isActive = !active;

			this.settingsData = [...this.settingsData];
		},
		handleCancel(event) {
			event.preventDefault(); // Prevent default form submission

			if (!this.patientId) {
				this.$router.push({ name: "HealthProfiles" });
			} else {
				this.$emit("closeProfileModal");
			}
		},
		onChangedAge(rules, index) {
			
			if (rules.length < 2) {
				return;
			}
			
			if (index === 0) {
				rules[1].minAge = Math.max(rules[0].maxAge + 1, this.adultMinAgeOptions[0]);
			} else {
				rules[0].maxAge = Math.min(rules[1].minAge - 1, this.adultMinAgeOptions[this.adultMinAgeOptions.length - 1]);
			}		
			
		},
		onChangeMinutes(rule, e){
			console.log("onChangeMinutes", rule, e);
			if (rule.minutes === 0){
				//e.target.type="text"
				e.target.value = null;
				rule.minutes = null;


				console.log("IT IS 0");
			}
		},
		isInError(procedureRule, ruleDetails) {
			if (!procedureRule.isActive || !ruleDetails.isActive) {
				return false;
			}
			if (procedureRule.isInterval) {
				if (ruleDetails.intervalInMonths !== null && (typeof ruleDetails.intervalInMonths !== 'number' || ruleDetails.intervalInMonths <= 0)) { //is not a positive number
					return true;
				}
			}
			if (procedureRule.defaultMinutes) {
				// if ((ruleDetails.minutes ?? -1) < 0 || ruleDetails.minutes === '') {
				// 	return true;
				// }
			}
			if (procedureRule.isAge) {
				if ((ruleDetails.minAge ?? -1) < 0) {
					return true;
				}
			}

			return false;



		},
		getLabel(rules, index) {			
			if (rules.length < 2) {
				return null;
			}
			let ruleDetails = rules[index];
			if ((ruleDetails.maxAge === null || ruleDetails.maxAge === 255)){
				return "Adults";
			} else if (ruleDetails.minAge! >= 0 && ruleDetails.maxAge! <= 25){
				return "Kids";
			} else {
				return null;
			}


		},
		async submit(e) {
			try {
				e.preventDefault();
				let clinicProcedureRules = new Array();

				for (const grp of this.settingsData) {
					for (const procedureRule of grp.data) {
						if ((procedureRule.isActive || procedureRule.healthProfileId != null)
							|| procedureRule.rules.some(x => x.healthProfileId == this.selectedHealthProfile
							|| procedureRule.rules.some(x => x.patientId == this.patientId))) {
							const backendRule = { procedureRuleTypeId: procedureRule.procedureRuleTypeId, healthProfileId: 0, patientId: this.patientId, rules: new Array() }
							for (let rule of procedureRule.rules) {
								backendRule.rules.push({
									procedureRuleId: rule.procedureRuleId === 0 ? null : rule.procedureRuleId,
									intervalInMonths: rule.intervalInMonths,
									includeWithScaling: rule.includeWithScaling,
									minAge: rule.minAge,
									maxAge: rule.maxAge,
									active: rule.isActive,
									defaultInMinutes: rule.minutes,
									healthProfileId: null,
									patientId: this.patientId
								})
							}
							clinicProcedureRules.push(backendRule);
						}
					}
				}

				let res = await ApiService.postData("/ProcedureRules/PatientRules", clinicProcedureRules, {});
				if (res.status == 200) {
					//this.getSettings();
					this.saved = true;
					window.scrollTo(0, 0);
					let self = this;
					setTimeout(() => {
						self.saved = false;
					}, 2500);
					this.$emit("closeProfileModal", true);
				} else {
					this.toast.error(codes.error)
				}
			} catch (e) {
				this.toast.error(codes.error)
			}
		},
		searchGroupColor(array, color) {
			for (let i = 0; i < array.length; i++) {
				const element = array[i];
				if (element.color == color) {
					return i;
				}
			}
			return -1;
		},
		async getSettings() {
			try {
				let res = await ApiService.get("/ProcedureRules");
				if (res.status === 200) {				
					let data = res.data;	

					// There are two routes in to this:
					// 1. From the edit patient dna link. In this case, we need the rules for the patient, regardless of what health profile the patient is assigned to
					// 2. From a due button on a procedure. In this case, we need the rules for the procedure and the pateint id or, if no patient id, the assigned health profile. This gets handled later

					// if path 1 is the case
					if (this.procedureRuleTypeId === undefined && this.patientId !== undefined) {
						data = data
							.map(procedure => {
								if (!procedure.ruleValues?.rules) return null; // If there are no rules, exclude this procedure
								
								// Filter rules by patientId
								let filteredRules = procedure.ruleValues.rules.filter(rule => rule.patientId === this.patientId);
								
								// If no matching rules, exclude the procedure
								if (filteredRules.length === 0) return null;

								return {
									...procedure,
									ruleValues: {
										...procedure.ruleValues,
										rules: filteredRules
									}
								};
							})
							.filter(procedure => procedure !== null); // Remove null values (procedures that had no matching rules)
					}

					let final = [] as any;

					// Now check if path 2 is the case
					if (this.procedureRuleTypeId != undefined && this.procedureRuleTypeId > 0) {
						if (data.some(item => item.procedureRuleTypeId == this.procedureRuleTypeId)) {
							data = data.filter(item => item.procedureRuleTypeId == this.procedureRuleTypeId);
						}

						data = data.map(procedure => {						
							let finalRules = [];
							
							// If there's a proc record for this patientId, get that one, otherwise get the one for the assigned health profile
							if (procedure.ruleValues.rules.some(rule => rule.patientId == this.patientId)) {
								finalRules = procedure.ruleValues.rules.filter(rule => rule.patientId === this.patientId);
							} 
							else if (procedure.ruleValues.rules.some(rule => rule.healthProfileId == this.profileId && rule.patientId == null)) { 
								finalRules = procedure.ruleValues.rules.filter(rule => rule.healthProfileId == this.profileId && rule.patientId == null); 
							} 
							else { 
								finalRules = procedure.ruleValues.rules.filter(rule => rule.healthProfileId == null && rule.patientId == null); 
							}

							// check to see if there are age specific rules, and filter the one applicable to patient
							if (this.patientAge && finalRules.length >= 2) {
								finalRules = finalRules.filter((rule: RuleDetails) => {
									const minAge = Number(rule.minAge ?? 0);
									const maxAge = Number(rule.maxAge ?? Infinity);

									return (this.patientAge ?? 0) >= minAge && (this.patientAge ?? 0) <= maxAge;
								});
							}

							return {
								...procedure,
								ruleValues: {
									...procedure.ruleValues,
									rules: finalRules
								}
							};
						});
					}

					data.forEach((element) => {
						let procedureRule = new ProcedureRule();
						procedureRule.name = element.name;
						procedureRule.procedureRuleTypeId = element.procedureRuleTypeId;
						procedureRule.color = element.procedureGroupColor;
						if (element.options) {
							procedureRule.options = JSON.parse(element.options).internvals.map(o => new Option(o.duration, o.units));
						}
						procedureRule.isAge = element.isAge;
						procedureRule.isInterval = element.isInterval;
						procedureRule.defaultMinutes = element.defaultMinutes;						
						procedureRule.isActive = element.ruleValues && (element.ruleValues.rules.filter(r => r.active).length > 0);
						procedureRule.rules = new Array<RuleDetails>();

						procedureRule.hasAdvanced = element.procedureRuleTypeId === 4 ||  //bitewings 
													element.procedureRuleTypeId === 14 ; //pans only for now

						let rules = new Array();
						if (element.ruleValues && element.ruleValues.rules.length > 0) {
							
							for (const rule of element.ruleValues.rules) {
								let ruleDetails = new RuleDetails();
								ruleDetails.isActive = rule.active;
								ruleDetails.minAge = rule.minAge;
								ruleDetails.maxAge = rule.maxAge;
								ruleDetails.minutes = rule.defaultInMinutes;
								ruleDetails.intervalInMonths = rule.intervalInMonths;
								ruleDetails.procedureRuleId = rule.procedureRuleId;
								ruleDetails.includeWithScaling = rule.includeWithScaling;
								ruleDetails.healthprofileId = rule.healthprofileId;
								ruleDetails.patientId = this.patientId ?? null;
								procedureRule.rules.push(ruleDetails);
							}
							DateHelper.sortArrayByProperty(procedureRule.rules as [], "minAge", "asc")
						}

						let index = this.searchGroupColor(final, element.procedureGroupColor);
						if (index != -1) {
							final[index].data.push(procedureRule);
						} else {
							let newGrp = { color: element.procedureGroupColor, data: [] as any };
							newGrp.data.push(procedureRule);
							final.push(newGrp);
						}
					});



					//sort final by color with the following order: "yellow", "lightyellow", "lavender", "violet", "orange", "grey", "red"
					final.sort((a, b) => {
						const order = ["yellow", "lightyellow", "lavender", "violet", "orange", "grey", "red"];
						return order.indexOf(a.color) - order.indexOf(b.color);
					});

					final.forEach((group) => {
						DateHelper.sortArrayByProperty(group.data, "name", "asc");
					})

					this.settingsData = final;
					this.settingsDataOld = JSON.parse(JSON.stringify(final));

					console.log(final);
				} else {
					this.toast.error(codes.error)
				}
			} catch (e) {
				console.log(e);
				this.toast.error(codes.error)

			}
		},
	},
});

class ProcedureRule {
	procedureRuleTypeId!: number;
	defaultMinutes!: number | null;
	isInterval!: boolean;
	name!: string;
	isAge!: boolean;
	color!: string;
	options!: Array<Option>; //not sure what to make this yet;
	rules!: Array<RuleDetails>;
	isActive: boolean = false;
	hasAdvanced: boolean = true;	
}

class RuleDetails {
	procedureRuleId!: number | null;
	isActive: boolean = false;
	minutes!: number | null;
	minAge!: number | null;
	maxAge!: number | null;
	intervalInMonths!: number | null;
	label!: string | null;
	includeWithScaling!: boolean | null;
	healthprofileId: number | null = null;
	patientId: number | null = null;
}

class Option {
	duration!: number;
	units!: "years" | "months";
	isSelected!: boolean;
	getLabel() {
		return `${this.duration} ${this.units}`
	}
	/**
	 *
	 */
	constructor(duration, units) {
		this.duration = duration;
		this.units = units;
	}
}
