
import useValidate from "@vuelidate/core";
import { required, helpers, sameAs, requiredIf } from '@vuelidate/validators';
import { defineComponent, reactive, computed, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { Models } from "@/Models/Models";
import {useToast} from "vue-toastification";
import {codes} from "@/Codes/Codes";

interface FormState {
  healthProfileId: number;
  profileName: string;
  dateCreated: Date;
  dateDeleted: Date;
}

export default defineComponent({
  name: "EditProfile",
  props: {
    selectedProfile: {
      required: true,
      type: Object,
    },
  },
  components: {},
  data() {
    return {
      toast: useToast(),
      errorMessageCustom: "",
    };
  }, 
  mounted(){
    console.log("mounted now")    
  },
  methods: {
    showErrorMessage(message) {
      this.errorMessageCustom = message;
      let self = this;
      setTimeout(() => {
        self.errorMessageCustom = "";
      }, 5000);
    },
    async submit() {
      this.v$.$validate();
      var edit = this.state.healthProfileId > 0;
      if (!this.v$.$error) {
        const payload: any = {
          profileName: this.state.profileName,
          healthProfileId: this.state.healthProfileId,  
        };

        let healthProfile = await ApiService.postData("/ProcedureRules/HealthProfile", payload, {});
        if (healthProfile.status == 200) {
          if (edit)
            this.$emit("closeEditProfileForm");
          else          
            this.$emit("closeAddProfileForm");
        }
      }
    }
  },
  setup(props, context) {    
    console.log("props: ", props);

    // Define reactive state (don't replace the entire object later)
    let state = reactive<FormState>({
      healthProfileId: props.selectedProfile?.healthProfileId || 0,
      profileName: props.selectedProfile?.profileName || "",
      dateCreated: new Date(),
      dateDeleted: new Date(),
    });

    const rules = computed(() => ({
      profileName: {
        required: helpers.withMessage("Profile name is required.", required),
      },
    }));

    let v$ = useValidate(rules, state);

    const closeForm = () => {
      context.emit("closeEditHealthProfileForm");
    };

    return {
      state,
      closeForm,
      v$,
    };
  },
});
